function App() {
  return (
    <>
      <div>
        <title>Site Under Maintenance</title>
      </div>
      <div className="bg-gray-100 flex flex-col items-center h-2 pt-10">
      <img 
          src="/Intouch-Horizontal-Logo.svg"
          alt="Intouch logo"
          width={450}
          height={450}
          priority
        />
      </div>
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 text-center p-4 pt-20">
        <h1 className="text-4xl font-semibold text-gray-800 mb-4">We'll be back soon!</h1>
        <p className="text-xl text-gray-600 mb-6">
        Thank you for your patience while we perform some necessary maintenance on your webstore. We will have everything back online for you shortly!
        </p>
        <p className="text-xl text-gray-600 mb-6">For more information on services we offer, please visit our website: <a href="https://www.intouchelevate.com/">https://www.intouchelevate.com/</a></p>
        <div className="w-12 h-12 border-4 border-gray-300 border-t-blue-500 rounded-full animate-spin"></div>
      </div>
    </>
  );
};
export default App;
